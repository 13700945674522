<template>
    <z-plate has-bottom-border>
        <template #titleLeft>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="汇总" name="first"></el-tab-pane>
                <el-tab-pane label="明细" name="second"></el-tab-pane>
            </el-tabs>
        </template>

        <!-- 汇总 -->
        <div class="first" v-if="activeName === 'first'" v-loading="tableLoading">
            <section class="text-right">
                <el-button @click="exportAllInfo"><img src="../../assets/img/icon-export.png" alt class="icon"/>导出数据
                </el-button>
            </section>

            <!-- 表格 -->
            <el-table
                    class="z-table default-header-padding"
                    :data="tableData"
                    :span-method="handleSpanMethod"
                    v-if="tableData.length"
                    style="width: 100%"
            >
                <el-table-column type="index" label="序号" width="60"></el-table-column>

                <el-table-column prop="schoolName" label="学校名称"></el-table-column>

                <el-table-column prop="fileCount" label="作品总数"></el-table-column>

                <el-table-column :label="item.groupName" v-for="(item, sIndex) in tableData[0].groupList" :key="sIndex">
                    <el-table-column
                            :label="item1.channelName"
                            v-for="(item1, index2) in item.channelList"
                            :key="index2">
                        <template slot-scope="{ row }">
                            <span>{{row.groupList[sIndex].channelList[index2].videoCount}}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
        </div>

        <!-- 明细 -->
        <div class="second" v-else v-loading="tableLoading">
            <el-form class="second-form" inline>
                <el-form-item>
                    <el-input v-model="detailForm.schoolName" placeholder="学校名称" clearable maxlength="50"/>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="detailForm.videoName" placeholder="作品名称" clearable maxlength="50"/>
                </el-form-item>

                <el-form-item>
                    <el-input v-model="detailForm.randomCode" placeholder="视频编码" clearable maxlength="10"/>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="detailForm.channelId" placeholder="请选择" multiple clearable collapse-tags>
                        <el-option v-for="item in channelList" :key="item.channelId" :label="item.groupName + '-' + item.channelName" :value="item.channelId"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-select v-model="detailForm.videoStage" placeholder="请选择" clearable collapse-tags>
                        <el-option v-for="item in stageList" :key="item.value" :label="item.name" :value="item.value" ></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button icon="el-icon-search" @click="queryDetailList">搜索</el-button>
                </el-form-item>

                <el-form-item class="pull-right" style="margin-right: 0">
                    <el-button @click="exportDetailInfo"><img src="../../assets/img/icon-export.png" alt class="icon"/>导出数据
                    </el-button>
                    <el-button @click="exportDetailFile"><img src="../../assets/img/icon-export.png" alt class="icon"/>导出附件
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 表格 -->
            <el-table class="z-table" v-if="page.content.length" :data="page.content" style="width: 100%">
                <el-table-column type="index" label="序号" width="60"></el-table-column>

                <el-table-column prop="channelName" label="作品分类"></el-table-column>

                <el-table-column label="作品名称">
                    <template #default="{ row }">
                        <span class="color-primary cursor"
                              @click="checkWorks(row.videoId, row.videoType, row.videoStatus, row.videoUrl, row.videoName, row.mpgName)">{{row.videoName}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="randomCode" label="视频编码"></el-table-column>

                <el-table-column prop="videoTextName" label="附件">
                    <template #default="{ row }">
                        <a class="color-primary cursor" @click="showFile(row)" style="">{{row.videoTextName}}</a>
                    </template>
                </el-table-column>

                <el-table-column prop="schoolName" label="上报单位"></el-table-column>

                <el-table-column prop="userName" label="联系人"></el-table-column>

                <el-table-column prop="userPhone" label="电话"></el-table-column>

                <el-table-column prop="videoStatus" label="转码状态">
                    <template #default="{ row }">
                        <span v-if="row.videoType == 1"></span>
                        <span v-if="row.videoStatus == 1 && row.videoType == 0">上传成功</span>
                        <span v-if="row.videoStatus == 2 && row.videoType == 0">正在转码</span>
                        <span v-if="row.videoStatus == 3 && row.videoType == 0">转码成功</span>
                        <span v-if="row.videoStatus == 4 && row.videoType == 0" style="color: #e60808;">转码失败</span>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <z-pagination
                    v-if="pagination.totalPages > 0"
                    :page.sync="pagination.page"
                    :limit.sync="pagination.size"
                    :total="pagination.totalPages"
                    @pagination="init"
            ></z-pagination>
        </div>

        <v-check-works v-if="dialogVisible" :show.sync="dialogVisible" :data="dialogData"></v-check-works>
    </z-plate>
</template>

<script>
    import VCheckWorks from './CheckWorks'

    export default {
        components: {
            VCheckWorks
        },

        data() {
            return {
                videoUrl: '',
                picList: [],
                activityid: sessionStorage.getItem("group_activityid"),
                activeName: "first",
                tableData: [],
                page: {
                    content: []
                },
                pagination: {
                    page: 0, //当前页
                    size: 10, //分页条数
                    totalPages: 0 //总条数
                },

                detailForm: {
                    channelId: "",
                    videoName: "",
                    schoolName: "",
                    videoStage: "",
                    randomCode: ""
                },
                stageList: [
                    {name: "全部", value: ''},
                    {name: "未转码", value: 1},
                    {name: "正在转码", value: 2},
                    {name: "转码成功", value: 3},
                    {name: "转码失败", value: 4},
                ],
                channelList: [],
                tableLoading: true,
                dialogVisible: false,
                dialogData: {}

            };
        },

        mounted() {
            this.queryChannelByActivityId();
            this.queryAllInfo();
        },

        methods: {
            showFile(obj) {
                var houzhui = obj.videoText.split(".")[1].toLowerCase();
                // console.log('在线查看文件', obj, obj.videoText, houzhui);
                if (houzhui == 'doc' || houzhui == 'docx' || houzhui == 'xls' || houzhui == 'xlsx') {
                    let routeUrl = this.$router.resolve({
                        path: "/ShowFile",
                        query: {videoId: obj.videoId}
                    });
                    window.open(routeUrl.href, '_blank');
                } else {
                    this.$message({
                        type: 'info',
                        message: '不支持预览……'
                    });
                    window.open("api/downTextFile?videoId="+obj.videoId+"&access_token="+sessionStorage.getItem("ACCESS_TOKEN"));
                }
            },
            //videoUrl 视频地址
            checkWorks(videoid, type, status, videoUrl, videoName, mpgName) {
                this.dialogData = {}
                this.$nextTick(() => {
                    if (type == 0) {//视频类
                        if (status != 3) {
                            if (status == 4) {
                                this.$message({
                                    type: 'info',
                                    message: '视频失败！！！'
                                });
                            } else if (status == 2) {
                                this.$message({
                                    type: 'info',
                                    message: '视频正在转码中...'
                                });
                            }
                            return;
                        } else {
                            this.dialogData = {
                                type: 0,
                                url: videoUrl,
                                name: videoName
                            }
                            this.dialogVisible = true;
                        }
                    } else if (type == 1) {//图片类
                        this.dialogVisible = true;
                        this.loadPicListByVideoId(videoid).then(rep => {
                            let videoname = videoName;
                            if (rep.content.picList.length > 1) {
                                videoname += "（组图）";
                            }

                            this.dialogData = {
                                type: 1,
                                url: rep.content.picList,
                                name: videoname
                            }
                        });
                    }else{
                      this.$message({
                        type: 'info',
                        message: '文件正在下载……'
                      });
                      var fileUrl = videoUrl+"?n="+mpgName;
                      // console.log('videoUrl=', fileUrl);
                      window.open(fileUrl, '_blank');
                    }
                })
            },
            loadPicListByVideoId(videoid) {
                return this.$post('/loadPicListByVideoId', {"videoid": videoid})
            },

            queryAllInfo() {
                this.tableData = [];
                this.tableLoading = true;
                this.$post('/queryHuiZongByActivityId', {
                    activityid: this.activityid
                }).then(rep => {
                    this.$nextTick(() => {
                        this.tableData = rep.content.tableData;
                        this.tableLoading = false;
                    })
                })
            },
            exportAllInfo() {
                if (!this.tableData.length) {
                    this.$message({
                        type: 'info',
                        message: '暂无导出数据信息'
                    });
                    return;
                }
                window.open("api/exportHuiZongByActivityId?activityid=" + this.activityid + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN"));
            },
            queryDetailList() {
                this.pagination.page = 0;
                this.init();
            },
            //根据活动id查询分类
            queryChannelByActivityId() {
                this.$post('/queryChannelById', {"activityId": this.activityid}).then(rep => {
                    this.channelList = rep.content.list;
                })
            },
            exportDetailInfo() {
                var channelid = '';
                if (this.detailForm.channelId) {
                    channelid = this.detailForm.channelId.join();
                }
                var url = "api/exportDetailListByActivityId?activityid=" + this.activityid + "&channelId=" + "&" + channelid + this.$qs.stringify(this.detailForm) + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN");
                // console.log('导出明细', url);
                window.open(url);
            },
            exportDetailFile() {
                var channelid = '';
                if (this.detailForm.channelId) {
                    channelid = this.detailForm.channelId.join();
                }
                var url = "api/downTextFileZip?activityid=" + this.activityid + "&channelId=" + channelid + "&" + this.$qs.stringify(this.detailForm) + "&access_token=" + sessionStorage.getItem("ACCESS_TOKEN");
                // console.log('导出附件', url);
                window.open(url);
            },
            init() {
              var channelid = '';
              if (this.detailForm.channelId) {
                  channelid = this.detailForm.channelId.join();
              }
              let {pagination} = this;
              this.tableLoading = true;
              this.$post('/queryDetailListByActivityId', {
                  activityid: this.activityid,
                  channelId: channelid,
                  videoName: this.detailForm.videoName,
                  schoolName: this.detailForm.schoolName,
                  videoStage: this.detailForm.videoStage,
                  randomCode: this.detailForm.randomCode,
                  page: pagination.page,
                  size: pagination.size
              }).then(rep => {
                  this.$nextTick(() => {
                      this.page = rep.content.page;
                      pagination.page = this.page.number;
                      pagination.totalPages = this.page.totalElements;
                      this.tableLoading = false;
                  })
              })
            },
            handleClick() {
              this.tableLoading = true;
              if (this.activeName === 'second') {
                  this.pagination.page = 0; //当前页
                  this.pagination.size = 10; //分页条数
                  this.init();
              } else {
                  this.queryAllInfo();
              }
            },

            handleSpanMethod({row, column, rowIndex, columnIndex}) {
              // console.log(row,columnIndex)
              if (row.schoolName == '合计' && columnIndex === 0) {
                  return [0, 0]
              }

              if (row.schoolName == '合计' && columnIndex === 1) {
                  return [1, 2]
              }
            }
        }
    };
</script>

<style lang="scss" scoped>
    .first {
        & > section {
            padding: 15px 20px;
        }
    }

    .second-form {
        padding: 15px 20px 0;
    }

</style>
